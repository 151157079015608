import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <h1>Contact</h1>
    <p>
      Feel free to contact me at: mail (at) dardan (dot) im.
      <br/><br/>
      Github: <Link to='https://github.com/darddan' target='_blank'>darddan</Link><br/>
      Twitter: <Link to='https://twitter.com/darddanh' target='_blank'>@darddanh</Link><br/>
      Instagram: <Link to='https://instagram.com/dardddan' target='_blank'>@dardddan</Link>
    </p>
  </Layout>
)

export default ContactPage
